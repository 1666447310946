<style scoped lang="scss">
  .role_list_box {
    .addbtn {
      margin-bottom: 10px;
    }
    /deep/ .c-el-dialog {
      max-width: 520px;
    }
  }
</style>

<template>
  <div class="role_list_box">
    <div class="addbtn">
      <el-button type="primary" plain size="small" @click="_edit()"><i class="fa fa-plus"></i> 新增</el-button>
    </div>
    <el-table :data="tableData">
      <el-table-column label="角色名" prop="displayName"></el-table-column>
      <el-table-column label="是否默认角色">
        <template slot-scope="scope">
          {{ scope.row.isDefault ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="角色描述" prop="description"></el-table-column>
      <el-table-column label="创建时间" prop="creationTime"></el-table-column>
      <el-table-column label="操作" width="100px">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <el-button type="primary" plain size="mini">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="_edit(scope.row)">编辑</el-dropdown-item>
              <el-dropdown-item @click.native="_delete(scope.row)">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container" v-if="isPagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
      :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
      layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>

    <el-dialog title="角色编辑" custom-class="c-el-dialog" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
      <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="100px" size="small">
        <el-form-item label="角色名称" prop="displayName">
          <el-input placeholder="请输入角色名称" v-model="dialogData.displayName"></el-input>
        </el-form-item>
        <el-form-item label="默认角色" prop="isDefault">
          <el-radio-group v-model="dialogData.isDefault">
            <el-radio :label="false" border size="small" style="margin-right: 5px;">否</el-radio>
            <el-radio :label="true" border size="small">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input type="textarea" rows="5" placeholder="请输入角色描述" v-model="dialogData.description"></el-input>
        </el-form-item>
        <el-form-item label="权限" prop="grantedPermissions">
          <div>
            <el-tree :props="defaultProps" node-key="id" :data="treeData" :check-strictly="false" :default-expanded-keys="checkedKeys"
              :default-checked-keys="checkedKeys" ref="roleTree" show-checkbox @check="checkChange"></el-tree>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="_close()">取消</el-button>
        <el-button size="small" type="primary" plain @click="_save()">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import configMixins from "@/mixins/config"
  import * as roles from '@/api/system/role'
  export default {
    name: "role",
    mixins: [configMixins],
    data() {
      return {
        tableData: [],
        total: null,
        listQuery: {
          keywords: null,
          page: 1,
          size: window.$common.appPageSize
        },
        isPagination: false,
        showDialog: false,
        dialogType: "",
        dialogData: {},
        formRefName: 'formRefName',
        formRules: {
          displayName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
          isDefault: [{ required: true, message: '请选择是否默认角色', trigger: 'blur' }],
          grantedPermissions: [{ required: true, message: '选择权限', trigger: 'blur' }]
        },
        defaultProps: {
          label: 'label',
          children: 'children'
        },
        allPermissions: [], // 临时存储的需要转换的权限
        treeData: [], // 绑定的权限数据
        checkedKeys: [] // 默认选中的权限数据
      }
    },
    mounted() {
      this.getListData()
      this.getPermissions()
    },
    methods: {
      handleSizeChange(val) {
        window.$common.fullLoading()
        this.listQuery.size = val
        this.getListData()
      },
      handleCurrentChange(val) {
        window.$common.fullLoading()
        this.listQuery.page = val
        this.getListData()
      },
      getListData() {
        window.$common.fullLoading()
        roles.getAll(this.listQuery).then(response => {
          window.$common.closeFullLoading()
          this.total = response.totalCount
          this.isPagination = response.totalCount > 0 || false
          if (response && response.items && response.items.length > 0) {
            this.tableData = response.items
          } else {
            this.tableData = []
          }
        })
      },
      _edit(row) {
        this.showDialog = true
        this.checkedKeys = []
        if (row) {
          this.dialogType = this.createOrUpdate[1]
          roles.getDetail({Id: row.id}).then(res => {
            this.dialogData = { ...res }
            this.treeData = this.trancePermission(this.allPermissions, res)
          })
        } else {
          this.dialogType = this.createOrUpdate[0]
          this.dialogData = {
            id: 0,
            name: "",
            displayName: "",
            isStatic: false,
            isDefault: false,
            description: "",
            grantedPermissions: null
          }
          this.treeData = this.trancePermission(this.allPermissions)
        }
      },
      _save() {
        this.$refs[this.formRefName].validate(valid => {
          if (valid) {
            window.$common.fullLoading()
            let commitTemp = {
              id: this.dialogData.id,
              displayName: this.dialogData.displayName,
              isDefault: this.dialogData.isDefault,
              description: this.dialogData.description,
              grantedPermissions: this.dialogData.grantedPermissions  
            }
            if (this.dialogType === this.createOrUpdate[0]) {
              roles.create(commitTemp).then(() => {
                this.commonSuccess()
              })
            } else if (this.dialogType === this.createOrUpdate[1]) {
              roles.update(commitTemp).then(() => {
                this.commonSuccess()
              })
            }
            
          } else {
            return false
          }
        })
      },
      getPermissions() {
        roles.getAllPermissions().then(res => {
          if (res && res.items && res.items.length > 0) {
            this.allPermissions = res.items
          }
        })
      },
      trancePermission(items, curItem) {
        let permissionData = []
        for (let i in items) {
          let temp = {}
          let item = items[i]
          temp.id = item.name
          temp.label = item.displayName
          // 有子集递归执行
          if (item.children && item.children.length > 0) {
            temp.children = this.trancePermission(item.children, curItem)
          }
          if (!curItem) {
            this.checkedKeys = []
          } else {
            // 仅仅将最后一级加入默认选中数组，父级会自动选中，自动展开
            if ((!item.children || item.children.length <= 0) && curItem.grantedPermissions.indexOf(item.name) !== -1) {
              this.checkedKeys.push(item.name)
            }
          }
          permissionData.push(temp)
        }
        return permissionData
      },
      _delete(row) {
        if (row) {
          this.confirm("确定要删除名为“" + row.displayName + "”的角色吗？").then(() => {
            window.$common.fullLoading()
            roles.remove({ id: row.id }).then(res => {
              this.commonSuccess()
            })
          })
        }
      },
      _close() {
        this.showDialog = false
        this.$nextTick(() => {
          if (this.$refs[this.formRefName]) {
            this.$refs[this.formRefName].resetFields()
          }
        })
      },
      commonSuccess(val) {
        let tips = val || "操作成功!"
        this.alert(tips).then(() => {
          this._close()
          window.$common.closeFullLoading()
          this.getListData()
        })
      },
      checkChange(data, isChecked, childChecked) {
        var curHalfKey = this.$refs.roleTree.getHalfCheckedKeys()
        var curKey = this.$refs.roleTree.getCheckedKeys()
        var commitKeys = curHalfKey.concat(curKey)
        this.dialogData.grantedPermissions = commitKeys || []
      }
    }
  }
</script>